<template>
  <v-row>
    <v-expansion-panels multiple accordion>
      <template v-for="child in section.children">
        <v-expansion-panel :key="child.title" lazy>
          <v-expansion-panel-header class="py-0">
            <div>
              {{ child.title }}
              <Dialog
                v-if="child.chart"
                icon="insert_chart"
                tooltip="Show Chart"
                :title="child.title"
                :body="child.chart"
              ></Dialog>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <template v-if="child.children">
              <keep-alive v-for="grandchild in child.children" :key="grandchild.title">
                <component :is="grandchild.component" v-bind="grandchild.props"></component>
              </keep-alive>
            </template>
            <template v-else-if="child.component">
              <keep-alive>
                <component
                  :is="child.component"
                  v-bind="child.props"
                  :title="child.title"
                ></component>
              </keep-alive>
            </template>
            <template v-else>
              <v-card> Work In Progress </v-card>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </v-row>
</template>

<script>
export default {
  name: "OutputSection",
  components: {
    Dialog: () => import("@/components/Dialog.vue"),
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewChart: {},
    };
  },
  methods: {
    openChart(idx) {
      console.debug("open", idx);
      this.$set(this.viewChart, idx, true);
    },
    closeChart(idx) {
      console.debug("close", idx);
      this.$set(this.viewChart, idx, false);
    },
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
